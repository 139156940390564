
import http from '../axios'


/* 价格行情的详情页 */
export const $getPriceHqDetail = (params) => {
    return http.post('/content/info', params)
}



export const $getContentPriceHq = params => {
    return http.post('/content', params)
}